import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g04'

let batchAddUrl = 'batchAdd'
let aggregationUrl = 'aggregation'
let tenderStatUrl = 'tenderStats'
let detailTenderStatUrl = 'detailTenderStats'

class G04Service extends RestService {
  batchAdd (g04Models) {
    g04Models.forEach(g04Model => {
      g04Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g04Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStat (year, month, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDetailTenderStat (year, month, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, detailTenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  aggregation (tenderGuid, year, month) {
    return axios.get(utility.getRestFullUrl(this.resourceName, aggregationUrl), {
      params: {
        tenderGuid,
        year,
        month
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g04Service = new G04Service(resourceName)

export default g04Service
